<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="800"
        persistent
      >
        <v-card class="px-7 pt-7 pb-5">
          <v-card-title class="pa-0 pb-5">
            <h2 class="mb-20">入力内容の確認</h2>
          </v-card-title>

          <v-card-text class="px-0">
            <!-- システムプラン -->
            <table class="parent-table">
              <tr class="category-title primary font-weight-bold">
                <td colspan="2">システムプラン</td>
              </tr>
              <tr>
                <td class="primary"><label>選択したプラン</label></td>
                <td class="data">{{ selectedPlan.plan_name }}</td>
              </tr>
            </table>

            <!-- 営業情報 -->
            <table class="parent-table">
              <tr class="category-title primary font-weight-bold">
                <td colspan="2">店舗営業情報</td>
              </tr>
              <tr>
                <td class="primary"><label>店舗名</label></td>
                <td class="data">{{ shopInfo.shop_name }}</td>
              </tr>
              <tr>
                <td class="primary"><label>店舗名かな表記</label></td>
                <td class="data">{{ shopInfo.shop_name_kana }}</td>
              </tr>
              <tr>
                <td class="primary"><label>営業種別</label></td>
                <td class="data">{{ bizType.business_type }}</td>
              </tr>
              <tr>
                <td class="primary"><label>店舗所在の都道府県</label></td>
                <td class="data">{{ shopInfo.prefecture }}</td>
              </tr>
              <tr v-if="shopInfo.area">
                <td class="primary"><label>営業のメイン地域</label></td>
                <td class="data">{{ shopInfo.area }}</td>
              </tr>
              <tr>
                <td class="primary"><label>受付時刻</label></td>
                <td class="data">{{ shopInfo.opening_hour }} ~ {{ shopInfo.closing_hour }}</td>
              </tr>
              <tr>
                <td class="primary"><label>店舗受付用の電話番号</label></td>
                <td class="data">{{ shopInfo.phone }}</td>
              </tr>
              <tr v-if="shopInfo.address">
                <td class="primary"><label>店舗型営業の際の住所</label></td>
                <td class="data">{{ shopInfo.address }}</td>
              </tr>
              <tr>
                <td class="primary"><label>既存のオフィシャルHP</label></td>
                <td class="data">{{ shopInfo.official_url }}</td>
              </tr>
            </table>

            <!-- 管理者情報 -->
            <table class="parent-table">
              <tr class="category-title primary font-weight-bold">
                <td colspan="2">店舗管理情報（非公開）</td>
              </tr>
              <tr>
                <td class="primary"><label>店舗責任者様氏名</label></td>
                <td class="data">{{ adminInfo.contact_name }}</td>
              </tr>
              <tr>
                <td class="primary"><label>氏名（かな）</label></td>
                <td class="data">{{ adminInfo.contact_name_kana }}</td>
              </tr>
              <tr>
                <td class="primary"><label>電話番号</label></td>
                <td class="data">{{ adminInfo.phone }}</td>
              </tr>
              <tr>
                <td class="primary"><label>メールアドレス</label></td>
                <td class="data">{{ adminInfo.email }}</td>
              </tr>
              <tr>
                <td class="primary"><label>事務所住所</label></td>
                <td class="data">{{ adminInfo.office_address }}</td>
              </tr>
              <tr>
                <td class="primary"><label>届出確認書の交付番号</label></td>
                <td class="data">{{ adminInfo.license_number }}</td>
              </tr>
              <tr>
                <td class="primary"><label>担当の公安委員会</label></td>
                <td class="data">{{ adminInfo.license_issuer }}</td>
              </tr>
              <tr>
                <td class="primary"><label>管轄警察署</label></td>
                <td class="data">{{ adminInfo.jurisdiction_body }}</td>
              </tr>
              <tr>
                <td class="primary"><label>営業届出確認書の画像</label></td>
                <td class="data">
                  {{ uploadedImages.length }}ﾌｧｲﾙ：
                  <span
                    v-for="image in uploadedImages"
                    :key="image.name"
                  >
                    "{{ image.name }}"<span v-if="uploadedImages.length > 1">／</span>
                  </span>
                </td>
              </tr>
            </table>

            <!-- アカウント情報 -->
            <table class="parent-table">
              <tr class="category-title primary font-weight-bold">
                <td colspan="2">アカウント情報</td>
              </tr>
              <tr>
                <td class="primary"><label>メールアドレス(ログインID)</label></td>
                <td class="data">{{ credential.loginId }}</td>
              </tr>
              <tr>
                <td class="primary"><label>パスワード</label></td>
                <td class="data">{{ credential.password }}</td>
              </tr>
            </table>
          </v-card-text>

          <v-card-actions class="pa-0 justify-end">
            <v-btn
              depressed
              color="primary"
              @click="formSubmitted"
            >
              送信する
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="close"
            >
              修正する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},

  props: {
    shopInfo: {
      type: Object,
      required: true
    },
    credential: {
      type: Object,
      required: true
    },
    adminInfo: {
      type: Object,
      required: true
    },
    uploadedImages: {
      type: Array,
      required: true
    },
    selectedPlan: {
      type: Object,
      required: true
    },
    bizType: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      openForm: false,
      submitCallback: null
    };
  },

  computed: {
    lastFive() {
      return value => value.slice(-5);
    },
  },

  methods: {
    open(handdownData) {
      this.submitCallback = handdownData.submitCallback;
      this.openForm = true;
    },

    close() {
      this.openForm = false;
    },

    formSubmitted() {
      if (this.submitCallback) {
        this.submitCallback();
      }
      this.close();
    }
  }
};
</script>

<style scoped>
.parent-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  margin-bottom: 0.5em;
}
tr, td {
  padding: 0.5em 0 0.5em 0.7em;
}
.parent-table > tr > td:first-child {
  width: 30%;
}
.parent-table > tr > td:nth-child(even) {
  width: 70%;
  background-color: #fff;
}
.category-title, label {
  color: white;
}
.data {
  border: thin solid var(--v-primary-lighten2)
}
td.primary {
  opacity: .7;
}
</style>
