<template>
  <v-container>
    <v-stepper
      class="pr-3 my-5"
      v-model="stepProgress"
      vertical
    >
      <h3 class="pt-7 pl-7">
        <span class="logo-font">{{ systemName }}</span>&trade;ご利用のお申し込み
      </h3>

      <!-- ステップ１ 利用規約 -->
      <v-stepper-step step="1"
        :complete="stepProgress > 1"
      >
        システム利用規約の確認と同意
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card flat>
          <v-card-text>
            <p>
              システムの利用申し込みに際し以下のシステム利用規約の内容をご確認ください。<br />
              内容にご同意いただけましたら「利用規約に同意する」をチェックして次の項目にお進みください。
            </p>
          </v-card-text>

          <v-card-text class="pb-0">
            <v-sheet v-html="termsOfUse"></v-sheet>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-checkbox
              v-model="isTermAgreed"
              label="利用規約に同意する"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-card-actions>
        </v-card>

        <div class="d-flex justify-end px-5">
          <v-btn
            depressed
            color="primary"
            @click="validateTermsOfUse"
          >次へ</v-btn>
        </div>
      </v-stepper-content>

      <!-- ステップ２ -->
      <v-stepper-step step="2"
        :complete="stepProgress > 2"
      >
        アカウント情報の入力
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-form ref="form-credential">
          <v-row>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model.trim="credential.loginId"
                label="メールアドレス (ログインID)"
                hint="登録確認メールを送信するので受信可能なアドレスを入力してください"
                persistent-hint
                type="email"
                counter="50"
                required
                :rules="[valiRules.required, valiRules.email, valiRules.max50, ()=>noSameLoginId]"
                @change="checkLoginIdExists"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="credential.password"
                label="パスワード"
                :append-icon="credential.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="credential.showPassword ? 'text' : 'password'"
                counter="15"
                required
                :rules="[valiRules.required, valiRules.max15]"
                @click:append="credential.showPassword = !credential.showPassword"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex justify-end pt-7">
          <v-btn
            depressed
            color="primary"
            @click="validateCredential"
          >次へ</v-btn>
          <v-btn
            class="ml-2"
            depressed text
            color="primary"
            @click="stepProgress--"
          >戻る</v-btn>
        </div>
      </v-stepper-content>

      <!-- ステップ３ -->
      <v-stepper-step step="3"
        :complete="stepProgress > 3"
      >
        店舗管理情報の入力
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-form ref="form-admin">
          <v-row>
            <!-- 氏名 -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="adminInfo.contact_name"
                label="店舗責任者様の氏名"
                counter="20"
                required
                :rules="[valiRules.required, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="adminInfo.contact_name_kana"
                label="氏名（かな）"
                counter="30"
                required
                :rules="[valiRules.required, valiRules.max30]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 電話 -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model.trim="adminInfo.phone"
                label="店舗責任者様の電話番号"
                counter="20"
                required
                :rules="[valiRules.required, valiRules.phone, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <!-- email -->
            <v-col cols="12" md="8">
              <v-text-field
                v-model.trim="adminInfo.email"
                label="店舗責任者様のメールアドレス"
                type="email"
                counter="100"
                hint="アカウントIDのメールアドレスとは別のものを使用してください"
                required
                :rules="[valiRules.required, valiRules.email, valiRules.max100]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 住所 -->
            <v-col cols="12">
              <v-text-field
                v-model.trim="adminInfo.office_address"
                label="事務所の住所"
                hint="営業届出確認書記載の事務所の所在地を記載してください"
                counter="100"
                required
                :rules="[valiRules.required, valiRules.max100]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 営業届出確認書 -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="adminInfo.license_number"
                label="風俗営業届出確認書の登録番号"
                required
                :rules="[valiRules.required, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="adminInfo.license_issuer"
                label="届出確認書発行委員会"
                required
                :rules="[valiRules.required, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="adminInfo.jurisdiction_body"
                label="管轄警察署"
                required
                :rules="[valiRules.required, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="uploadedImages"
                accept="image/png, image/jpeg"
                multiple counter outlined
                show-size
                prepend-icon="mdi-image-multiple"
                label="届出確認書の画像ファイルをアップロード"
                hint="[shiftキー]、[ctlキー／cmdキー]を使って複数の画像を選択すると一度に複数をアップロードできます"
                persistent-hint
                :rules="[valiRules.requiredArray, valiRules.images]"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    color="accent"
                    label small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex justify-end pt-7">
          <v-btn
            depressed
            color="primary"
            @click="validateAdminInfo"
          >次へ</v-btn>
          <v-btn
            class="ml-2"
            depressed text
            color="primary"
            @click="stepProgress--"
          >戻る</v-btn>
        </div>
      </v-stepper-content>

      <!-- ステップ４ -->
      <v-stepper-step step="4"
        :complete="stepProgress > 4"
      >
        店舗営業情報の入力
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-form ref="form-info"
          :lazy-validation="true"
        >
          <v-row no-gutters>
            <!-- 店舗名 -->
            <v-col cols="12">
              <v-text-field
                v-model.trim="shopInfo.shop_name"
                label="営業届出確認書記載の店舗名"
                counter="50"
                required
                :rules="[valiRules.required, valiRules.max50, ()=>noSameShopName || 'こちらの店舗名は使用できません']"
                @blur="checkShopNameExists"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="shopInfo.shop_name_kana"
                label="営業届出確認書記載の店舗名（かな）"
                counter="50"
                required
                :rules="[valiRules.required, valiRules.max50]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- ビジネスタイプ -->
            <v-col cols="12" sm="6">
              <v-select
                v-model="shopInfo.business_type_id"
                label="営業の業態"
                :items="bizTypes"
                item-value="id"
                item-text="business_type"
                item-color="primary"
                required
                :rules="[valiRules.required]"
              ></v-select>
            </v-col>
            <!-- グループ -->
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="shopInfo.group_name"
                label="グループ名"
                hint="グループ店舗間のアカウント連携の申請ができます"
                persistent-hint
                counter="30"
                :rules="[valiRules.max30]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 都道府県 -->
            <v-col cols="12" sm="6">
              <v-select
                v-model="shopInfo.prefecture"
                label="店舗所在の都道府県"
                :items="prefectures"
                item-value="prefecture"
                item-text="prefecture"
                item-color="primary"
                hint="営業届出確認書記載の事務所所在地の都道府県名を選択してください"
                required
                :rules="[valiRules.required]"
                @change="onPrefectureChange"
              ></v-select>
            </v-col>
            <!-- エリア -->
            <v-col cols="12" sm="6">
              <v-combobox
                v-model.trim="shopInfo.area"
                label="HPに記載する営業エリア"
                hint="リストに無い場合は手入力してください"
                persistent-hint
                :items="areas"
                :return-object="false"
                item-value="area"
                item-text="area"
                item-color="primary"
                counter="20"
                required
                :rules="[valiRules.required, valiRules.max20]"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <!-- 住所 -->
            <v-col cols="12">
              <v-text-field
                v-model.trim="shopInfo.address"
                label="店舗型営業の際の住所"
                hint="店舗受付けやサービスルーム等のお客様をご案内する住所を要する業態の店舗様は記載してください"
                counter="100"
                required
                :rules="isIncall ? [valiRules.required, valiRules.max100] : [valiRules.max100]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 電話 -->
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="shopInfo.phone"
                label="電話番号"
                counter="20"
                required
                :rules="[valiRules.required, valiRules.phone, valiRules.max20]"
              ></v-text-field>
            </v-col>
            <!-- オフィシャルURL -->
            <v-col cols="12" sm="8">
              <v-text-field
                v-model.trim="shopInfo.official_url"
                label="オフィシャルHPのURL"
                placeholder="https://example.com"
                hint="まだドメインを取得してない店舗様は空欄にしてください"
                counter="100"
                :rules="[valiRules.url, valiRules.max100, ()=>noSameURL || 'こちらのURLは使用できません']"
                @blur="checkOfficialUrlExists"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- 開始時刻 -->
            <v-col cols="12" sm="4">
              <v-menu top
                v-model="openPickerOpening"
                :close-on-content-click="false"
                :nudge-left="60"
                transition="scroll-y-reverse-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="shopInfo.opening_hour"
                    label="営業開始時刻"
                    prepend-icon="mdi-clock-start"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="[valiRules.required]"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="shopInfo.opening_hour"
                  format="24hr"
                  :allowed-minutes="m => m % 30 === 0"
                  header-color="primary"
                  color="primary"
                  @click:minute="openPickerOpening = false"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <!-- 終了時刻 -->
            <v-col cols="12" sm="4">
              <v-menu top
                v-model="openPickerClosing"
                :close-on-content-click="false"
                :nudge-left="60"
                transition="scroll-y-reverse-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="shopInfo.closing_hour"
                    label="営業終了時刻"
                    prepend-icon="mdi-clock-end"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="[valiRules.required]"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="shopInfo.closing_hour"
                  format="24hr"
                  :allowed-minutes="m => m % 30 === 0"
                  header-color="primary"
                  color="primary"
                  @click:minute="openPickerClosing = false"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex justify-end pt-7">
          <v-btn
            depressed
            color="primary"
            @click="validateShopInfo"
          >次へ</v-btn>
          <v-btn
            class="ml-2"
            depressed text
            color="primary"
            @click="stepProgress--"
          >戻る</v-btn>
        </div>
      </v-stepper-content>

      <!-- ステップ５ -->
      <v-stepper-step step="5"
        :complete="stepProgress > 5"
      >
        ご利用プランの選択
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-form ref="form-sysplan"
          class="px-3"
        >
          <v-item-group
            v-model="selectedSysplanIndex"
            mandatory
          >
            <v-row>
              <v-col cols="12" md="3"
                class="d-flex justify-strech"
                v-for="systemPlan in systemPlans"
                :key="systemPlan.id"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    class="plan-card d-flex flex-column pa-3"
                    :class="active ? 'selected-plan' : ''"
                    hover
                    @click="toggle"
                  >
                    <v-card-title>
                      {{ systemPlan.plan_name }}
                    </v-card-title>

                    <v-card-text
                      class="flex-shrink-0 flex-grow-1"
                    >
                      {{ systemPlan.description }}
                    </v-card-text>

                    <v-card-subtitle>
                      <p>月額：{{ price(systemPlan.monthly_charge) }}</p>
                      <p>初期費用：{{ price(systemPlan.initial_cost) }}</p>
                    </v-card-subtitle>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-form>

        <div class="d-flex justify-end pt-7">
          <v-btn
            depressed
            color="primary"
            @click="openFormConfirm"
          >入力内容の確認</v-btn>
          <v-btn
            class="ml-2"
            depressed text
            color="primary"
            @click="stepProgress--"
          >戻る</v-btn>
        </div>
      </v-stepper-content>

    </v-stepper>

    <!-- 確認用モーダル -->
    <form-confirm
      ref="formConfirm"
      :shopInfo="shopInfo"
      :credential="credential"
      :adminInfo="adminInfo"
      :uploadedImages="uploadedImages"
      :selectedPlan="selectedPlan"
      :bizType="bizType"
    ></form-confirm>

    <!-- 終了メッセージ用モーダル -->
    <modal-confirm ref="modalConfirm">
      <div style="text-align:left;" v-html="successMessage"></div>
    </modal-confirm>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import axios from 'axios'
import $literals from '@/literals.js'
import { API_ENDPOINT, SYSTEM_PROVIDER } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules, ConvertPhone } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import SignupFormConfirm from '@/components/SignupFormConfirm.vue'

export default {
  components: {
    'loader': Loader,
    'modal-confirm': ModalConfirm,
    'form-confirm': SignupFormConfirm
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      templateStrings: [],
      stepProgress: 1,
      isTermAgreed: false,
      credential: {showPassword: false},
      adminInfo: {},
      shopInfo: {},
      selectedSysplanIndex: 0,
      uploadedImages: [],
      prefectures: [],
      areas: [],
      systemPlans: [],
      bizTypes: [],
      openPickerOpening: '',
      openPickerClosing: '',
      noSameShopName: true,
      noSameLoginId: true,
      noSameURL: true,
      loading: false,
      loadingMessage: null,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      publicApi: new ApiTool(API_ENDPOINT + '/'),
      systemName: SYSTEM_PROVIDER.systemName,
      shopApi: null,
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    termsOfUse() {
      const target = this.templateStrings.find( template => template.item_name === 'terms_of_use' )
      return target !== undefined ? target.content : ''

    },
    successMessage() {
      const target = this.templateStrings.find( template => template.item_name === 'completed_signup_application' )
      return target !== undefined ? target.content : ''
    },
    bizType() {
      const target = this.bizTypes.find( row => row.id === this.shopInfo.business_type_id )
      return target !== undefined ? target : {}
    },
    isIncall() {
      const target = this.bizTypes.find( type => type.id === this.shopInfo.business_type_id )
      return target !== undefined ? target.is_incall : false
    },
    hostname() {
      return url => url.replace(/^https?:\/\//, '')
    },
    price() {
      return number => !number ? '無料' : (Number(number).toLocaleString() + '円')
    },
    selectedPlan() {
      return this.systemPlans[this.selectedSysplanIndex] || {}
    }
  },

  watch: {
    'shopInfo.phone': function(now, old) {
      if (!now) return;
      this.shopInfo.phone = ConvertPhone(now) || old;
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.$vuetify.theme.dark = false

    this.initForm().catch(error => CheckTokenError(error))
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async initForm() {
      await Promise.all([
        this.publicApi.getReqSystemPublic('template-string/', {usedIn: 'signup', itemName: ''}).then(results => this.templateStrings = [...results] ),
        this.publicApi.getReqSystemPublic('prefecture/').then( results => this.prefectures = [...results] ),
        this.publicApi.getReqSystemPublic('business-type/').then( results => this.bizTypes = [...results] ),
        this.publicApi.getReqSystemPublic('system-plan/').then( results => this.systemPlans = [...results] ),
      ])
    },

    //
    //地域取得
    //
    getAreas(prefecture) {
      const pref = this.prefectures.find( row => row.prefecture === prefecture )

      this.publicApi.getReqSystemPublic('area/', { prefectureId: pref.id }).then( results => this.areas = [...results] )
    },

    onPrefectureChange() {
      this.checkShopNameExists()
      this.getAreas(this.shopInfo.prefecture)
    },

    //重複IDチェック
    checkLoginIdExists() {
      const query = {loginId: this.credential.loginId}
      this.publicApi.getReqSystemPublic('exists/', query).then( response => {
        this.noSameLoginId = response ? false : true
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationSignupSameLoginId, open: !!response}}
      })
    },

    //重複店舗名チェック
    checkShopNameExists() {
      if (!this.shopInfo.shop_name || !this.shopInfo.prefecture) return true

      const query = {shopName: this.shopInfo.shop_name, prefecture: this.shopInfo.prefecture}
      this.publicApi.getReqSystemPublic('exists/', query).then( response => {
        this.noSameShopName = response ? false : true
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationSignupSameShopName, open: !!response}}
      })
    },

    //重複オフィシャルＵＲＬチェック
    checkOfficialUrlExists() {
      const query = {officialUrl: this.hostname(this.shopInfo.official_url)}
      this.publicApi.getReqSystemPublic('exists/', query).then( response => {
        this.noSameURL = response ? false : true
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationSignupSameUrl, open: !!response}}
      })
    },

    //利用規約バリデーション
    validateTermsOfUse() {
      if (this.isTermAgreed) {
        this.stepProgress++
      } else {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationSingupAgreeTerms, open: true}}
      }
    },

    //アカウント情報バリデーション
    validateCredential() {
      if (this.$refs['form-credential'].validate()) {
        this.stepProgress++
      } else {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
      }
    },

    //管理情報バリデーション
    validateAdminInfo() {
      if (this.$refs['form-admin'].validate()) {
        this.stepProgress++
      } else {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
      }
    },

    //営業情報バリデーション
    validateShopInfo() {
      if (this.$refs['form-info'].validate()) {
        this.stepProgress++
      } else {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
      }
    },

    //入力内容の確認
    openFormConfirm() {
      this.$refs.formConfirm.open({submitCallback: this.createShop})
    },

    //送信後モーダル
    openSuccessModal() {
      const modalHanddown = {noCallback: this.succeedToHome}
      this.$refs.modalConfirm.open(modalHanddown)
    },

    //ホームへ
    succeedToHome() {
      this.$router.push({ name: 'home', query: {loginId: this.credential.loginId} })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //店舗登録
    //**************************************************
    createShop() {
      this.loading = true
      this.loadingMessage = '店舗データ新規登録中・・・'

      const shopMaster = {
        shop_name: this.shopInfo.shop_name,
        shop_name_kana: this.shopInfo.shop_name_kana,
        group_name: this.shopInfo.group_name,
        is_licensed: false,
        business_type_id: this.shopInfo.business_type_id,
        prefecture: this.shopInfo.prefecture,
        area: this.shopInfo.area,
        phone: this.shopInfo.phone,
        address: this.shopInfo.address,
        official_url: this.hostname(this.shopInfo.official_url),
        opening_hour: this.shopInfo.opening_hour,
        closing_hour: this.shopInfo.closing_hour,
      }

      axios({
        method: 'POST',
        url: API_ENDPOINT + '/shop/create/',
        // headers: { 'Content-Type': 'multipart/form-data' },
        data: JSON.stringify(shopMaster)
      })
      .then( response => {
        //店舗IDで付随情報を登録
        this.shopApi = new ApiTool(API_ENDPOINT + '/shop/', response.data)

        const plan = {
          system_plan_id: this.selectedPlan.id,
          ad_plan_id: 0,
        }

        const adminInfo = {
          contact_name: this.adminInfo.contact_name,
          contact_name_kana: this.adminInfo.contact_name_kana,
          phone: this.adminInfo.phone,
          email: this.adminInfo.email,
          office_address: this.adminInfo.office_address,
          license_number: this.adminInfo.license_number,
          license_issuer: this.adminInfo.license_issuer,
          jurisdiction_body: this.adminInfo.jurisdiction_body,
        }

        const credential = {
          login_id: this.credential.loginId,
          password: this.credential.password,
          account_type: 'basic',
          account_name: this.shopInfo.shop_name,
          privilege_level: 'normal',
        }

        const licenseImages = new FormData()
        licenseImages.append('number_of_files', this.uploadedImages.length)
        this.uploadedImages.map( (item, i) => licenseImages.append('image_url_' + (i + 1), item) )

        Promise.all([
          this.shopApi.apiReqWithData('POST', 'plan/create/',  JSON.stringify(plan)),
          this.shopApi.apiReqWithData('POST', 'admin-info/create/', JSON.stringify(adminInfo)),
          this.shopApi.apiReqWithData('POST', 'credential/create/', JSON.stringify(credential)),
          this.shopApi.apiReqWithData('POST', 'license/create/', licenseImages),
        ])
        .then(() => this.openSuccessModal() )
        .catch(error => CheckTokenError(error))
        .then(() => this.loading = false )
      })
      .catch(error => {
        CheckTokenError(error)
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
>>> .terms {
  height: 300px;
  overflow: scroll;
  padding: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
}
>>> .terms h4 {
  margin-bottom: 5px;
}
>>> .terms li {
  margin-bottom: 1em;
}
>>> .terms li p {
  margin: 0 0 0.5em 1em;
}
>>> .terms .list-style li {
  list-style: disc;
}
>>> .terms .list-style {
  margin-left: 1em;
}
.plan-card {
  border: thin solid #f3f3f3;
}
.selected-plan {
  border-color: var(--v-primary-base);
}
>>> .v-time-picker-title {
  justify-content: center;
}
</style>
